import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Combine values from multiple observable sources.
 * As a result we get an array of values after all updates.
 * @param observables Observable Input sources.
 */
export const combineValues: typeof combineLatest = (observables: any): any =>
  combineLatest([...observables]).pipe(
    // We need this to make sure that every value used in combineLatest() was updated.
    debounceTime(0),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );
