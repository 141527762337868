import { marker } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * Available Languages
 */
export enum Language {
  /** English */
  English = 'en',
  /** Russian */
  Russian = 'ru',
  /** German */
  German = 'de',
  /** Japanese */
  Japanese = 'ja',
  /** Spanish */
  Spanish = 'es',
  /** Chinese */
  Chinese = 'zh',
  /** Korean */
  Korean = 'ko',
  /** Portuguese */
  Portuguese = 'pt',
  /** French */
  French = 'fr',
}

/**
 * Helper methods for Enum.
 */
export namespace Language {
  const TO_TITLE_MAP: Record<Language, string> = {
    [Language.English]: marker('lang.en'),
    [Language.Russian]: marker('lang.ru'),
    [Language.German]: marker('lang.de'),
    [Language.Japanese]: marker('lang.ja'),
    [Language.Spanish]: marker('lang.es'),
    [Language.Chinese]: marker('lang.zh'),
    [Language.Korean]: marker('lang.ko'),
    [Language.Portuguese]: marker('lang.pt'),
    [Language.French]: marker('lang.fr'),
  };

  /**
   * Convert a certain Enum value to readable title.
   * @param value value of Enum
   */
  export function toReadable(value: Language): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Get list of Enum items.
   */
  export function toArray(): Language[] {
    return [
      Language.English,
      Language.German,
      Language.Russian,
      Language.Japanese,
      Language.Spanish,
      Language.Chinese,
      Language.Korean,
      Language.Portuguese,
      Language.French,
    ];
  }

  /**
   * Track by function.
   */
  export function trackBy(_index: number, item: Language): Language {
    return item;
  }

  /** Flag is Americas. */
  export function isEnglish(item: Language): boolean {
    return Language.English === item;
  }

  /** Flag is Europe, the Middle East and Africa. */
  export function isEMEA(item: Language): boolean {
    return [
      Language.German,
      Language.French,
      Language.Portuguese,
      Language.Spanish,
      Language.Russian,
    ].includes(item);
  }

  /** Flag is ZH. */
  export function isZH(item: Language): boolean {
    return [Language.Chinese, Language.Japanese, Language.Korean].includes(item);
  }
}
