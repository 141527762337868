import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Round number pipe.
 */
@Pipe({
  name: 'roundNumber',
})
export class RoundNumberPipe implements PipeTransform {
  /**
   * Rounds passed value.
   * @param value Value to transform.
   * @param maxFractionDigits Max fraction digits.
   */
  public transform(value?: number | null, maxFractionDigits?: string | number): string | null {
    const format = maxFractionDigits ? `1.${maxFractionDigits}-${maxFractionDigits}` : '1.0-0';
    return this.decimalPipe.transform(value, format);
  }

  /**
   * @constructor
   * @param decimalPipe Decimal pipe.
   */
  public constructor(private readonly decimalPipe: DecimalPipe) { }
}
