import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import '@saritasa/angular-typed-forms';

import { IconsService } from '@cpcac/common/core/services';
import '@cpcac/common/core/utils/constructor-init-arg';
import { environment } from '@cpcac/common/environments/environment';
import { CommonSharedModule } from '@cpcac/common/shared/common-shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

let defaultLanguage = 'en';

switch (environment.country) {
  case 'US':
    defaultLanguage = 'en';
    break;
  case 'CN':
    defaultLanguage = 'zh';
    break;
}

/** Base app module. */
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    TranslateModule.forRoot({
      defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  /**
   * @constructor
   * @param iconsService Icons service.
   */
  public constructor(private readonly iconsService: IconsService) {
    this.iconsService.registerIcons();
  }
}
