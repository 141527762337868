import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { FEET_TO_METERS_RATIO, FL_TO_NITS_RATIO } from '../constants';
import { ProjectorType, Units } from '../enums';
import { FormInputs } from '../models';
import { filterNull, pickValue } from '../rxjs/public_api';

/**
 * Service to handle Calculations form change.
 */
@Injectable({
  providedIn: 'root',
})
export class CalculationsFormService {
  private readonly formValues$ = new BehaviorSubject<FormInputs | null>(null);
  private readonly isValidValue$ = new BehaviorSubject<boolean>(false);

  /** Form values. */
  public readonly values$ = this.formValues$.asObservable().pipe(
    filterNull(),
    map(values => {
      let {
        flatWidth, flatHeight, scopeWidth, scopeHeight, inputProjectionThrow,
        centerBrightness, centerBrightness3D,
      } = values;

      // We have to convert meter to feet because all calculations use feet as units.
      if (values.lengthUnits === Units.Meters) {
        flatWidth *= FEET_TO_METERS_RATIO;
        flatHeight *= FEET_TO_METERS_RATIO;
        scopeWidth *= FEET_TO_METERS_RATIO;
        scopeHeight *= FEET_TO_METERS_RATIO;
        inputProjectionThrow *= FEET_TO_METERS_RATIO;
      }

      // We have to convert nits to fL because all calculations use fL as units.
      if (values.centerBrightnessUnits === Units.Nits) {
        centerBrightness /= FL_TO_NITS_RATIO;
        centerBrightness3D /= FL_TO_NITS_RATIO;
      }

      return {
        ...values,
        flatWidth,
        flatHeight,
        scopeWidth,
        scopeHeight,
        inputProjectionThrow,
        centerBrightness,
        centerBrightness3D,
      };
    }),
  );

  /**
   * Converted form values.
   * We use them for displaying not for calculations.
   */
  public readonly convertedValues$ = this.values$.pipe(
    map(values => {
      let {
        flatWidth, flatHeight, scopeWidth, scopeHeight, inputProjectionThrow,
        centerBrightness, centerBrightness3D,
      } = values;

      // We have to convert form values back from feet to meter because all calculations use feet as units.
      if (values.lengthUnits === Units.Meters) {
        flatWidth /= FEET_TO_METERS_RATIO;
        flatHeight /= FEET_TO_METERS_RATIO;
        scopeWidth /= FEET_TO_METERS_RATIO;
        scopeHeight /= FEET_TO_METERS_RATIO;
        inputProjectionThrow /= FEET_TO_METERS_RATIO;
      }

      if (values.centerBrightnessUnits === Units.Nits) {
        centerBrightness *= FL_TO_NITS_RATIO;
        centerBrightness3D *= FL_TO_NITS_RATIO;
      }

      return {
        ...values,
        flatWidth,
        flatHeight,
        scopeWidth,
        scopeHeight,
        inputProjectionThrow,
        centerBrightness,
        centerBrightness3D,
      };
    }),
  );

  /** Form validation status. */
  public readonly isValid$ = this.isValidValue$.asObservable();

  /** Illumination type. */
  public readonly illumination$ = this.values$.pipe(
    pickValue('projectorType'),
    distinctUntilChanged(),
    map(type => type !== null ? ProjectorType.getIllumination(type) : null),
  );

  /**
   * Set calculations form values.
   * @param formValues Form values.
   */
  public setValues(formValues: FormInputs): void {
    this.formValues$.next(formValues);
  }

  /**
   * Set form validation status.
   * @param isValid Is form valid.
   */
  public setValidationStatus(isValid: boolean): void {
    this.isValidValue$.next(isValid);
  }
}
