import { ProjectorType } from '../enums';

import { EquipmentBase } from './equipment-base';
import { Lamp } from './lamp';
import { Lens } from './lens';
import { NohdLensType } from './nohd-lens-type';
import { Resolution } from './resolution';

/**
 * Projector
 */
export class Projector extends EquipmentBase {
  /** Resolution */
  public resolution: Resolution;

  /** Type */
  public type: ProjectorType;

  /** Max lumens */
  public maxLumens: number;

  /** Lenses */
  public lenses: Lens[];

  /** Lamps */
  public lamps: Lamp[];

  /** HD D USA */
  public hdDUsa: number;

  /** HD D NON USA */
  public hdDNonUsa: number;

  /** Color correction efficiency in percents. */
  public colorCorrectionEfficiencyPercent: number;

  /** NOHD lens factors. */
  public nohdLensType: readonly NohdLensType[];

  /** Maximum Contrast for High Brightness / Premium (HB) lens. */
  public hbContrast: string | null;

  /** Maximum Contrast for High Contrast / Premium (HC) lens. */
  public hcContrast: string | null;

  /** Maximum Contrast for Ultra-High Contrast / Premium (UHC) lens. */
  public uhcContrast: string | null;
  /**
   * Flag indicating that the Projector can only be selected manually.
   * If true, then this element should not participate in automatic selection
   * (for example, after applying some filter). The user can select the element only manually.
   */
  public manualSelectionOnly: boolean;

  public constructor(data: ConstructorInitArg<Projector>) {
    super(data);
    this.resolution = data.resolution;
    this.type = data.type;
    this.maxLumens = data.maxLumens;
    this.lenses = data.lenses;
    this.lamps = data.lamps;
    this.hdDUsa = data.hdDUsa;
    this.hdDNonUsa = data.hdDNonUsa;
    this.colorCorrectionEfficiencyPercent = data.colorCorrectionEfficiencyPercent;
    this.nohdLensType = data.nohdLensType;
    this.hbContrast = data.hbContrast;
    this.hcContrast = data.hcContrast;
    this.uhcContrast = data.uhcContrast;
    this.manualSelectionOnly = data.manualSelectionOnly;
  }
}
