import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { RESOLUTION_2K, RESOLUTION_4K } from '../constants/resolutions';
import { Resolution } from '../models/resolution';

import { IlluminationType } from './illumination-type';

/**
 * Projector type.
 */
export enum ProjectorType {
  /**
   * 2K Xenon.
   */
  Xenon2K,
  /**
   * 4K Xenon.
   */
  Xenon4K,
  /**
   * 2K Laser.
   */
  Laser2K,
  /**
   * 4K Laser.
   */
  Laser4K,
}

/**
 * Helper methods for Enum.
 */
export namespace ProjectorType {
  const TO_TITLE_MAP: Record<ProjectorType, string> = {
    [ProjectorType.Xenon2K]: marker('projector-type.xenon-2k'),
    [ProjectorType.Xenon4K]: marker('projector-type.xenon-4k'),
    [ProjectorType.Laser2K]: marker('projector-type.laser-2k'),
    [ProjectorType.Laser4K]: marker('projector-type.laser-4k'),
  };

  /**
   * Convert a certain Enum value to readable title.
   * @param value value of Enum
   */
  export function toReadable(value: ProjectorType): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Get list of Enum items.
   */
  export function toArray(): ProjectorType[] {
    return [
      ProjectorType.Xenon2K,
      ProjectorType.Xenon4K,
      ProjectorType.Laser2K,
      ProjectorType.Laser4K,
    ];
  }

  /**
   * Track by function.
   */
  export function trackBy(_index: number, item: ProjectorType): ProjectorType {
    return item;
  }

  /**
   * Comparator for sort function.
   */
  export function sortComparator(first: ProjectorType, second: ProjectorType): number {
    const allItems = ProjectorType.toArray();
    return allItems.indexOf(first) - allItems.indexOf(second);
  }

  /**
   * Check if selected projector type is 2K.
   * @param value value of Enum
   */
  export function is2K(value: ProjectorType | null): boolean {
    return value === ProjectorType.Laser2K || value === ProjectorType.Xenon2K;
  }

  /**
   * Check if selected projector type is 4K.
   * @param value value of Enum
   */
  export function is4K(value: ProjectorType | null): boolean {
    return value === ProjectorType.Laser4K || value === ProjectorType.Xenon4K;
  }

  /**
   * Check if selected projector type is Xenon.
   * @param value value of Enum
   */
  export function isXenon(value: ProjectorType | null): boolean {
    if (value === null) {
      return false;
    }

    return getIllumination(value) === IlluminationType.Xenon;
  }

  /**
   * Check if selected projector type is Laser.
   * @param value value of Enum
   */
  export function isLaser(value: ProjectorType | null): boolean {
    if (value === null) {
      return false;
    }

    return getIllumination(value) === IlluminationType.Laser;
  }

  /**
   * Get Resolution for projector type
   */
  export function getResolution(value: ProjectorType): Resolution {
    switch (value) {
      case ProjectorType.Laser2K:
      case ProjectorType.Xenon2K:
        return RESOLUTION_2K;
      case ProjectorType.Laser4K:
      case ProjectorType.Xenon4K:
        return RESOLUTION_4K;
    }
  }

  /**
   * Get illumination type for projector type
   */
  export function getIllumination(value: ProjectorType): IlluminationType {
    switch (value) {
      case ProjectorType.Laser2K:
      case ProjectorType.Laser4K:
        return IlluminationType.Laser;
      case ProjectorType.Xenon2K:
      case ProjectorType.Xenon4K:
        return IlluminationType.Xenon;
    }
  }
}
