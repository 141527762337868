import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import * as CONSTANTS from '../constants';
import { ScreenConfiguration } from '../enums';
import { ImageValues } from '../models/image-values';
import { pickProperties } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';

/**
 * Image analysis service.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageAnalysisService {
  /**
   * @constructor
   * @param formService Form service.
   */
  public constructor(private readonly formService: CalculationsFormService) {
  }

  private readonly formValues$ = this.formService.values$.pipe(
    pickProperties('screenConfiguration', 'flatWidth', 'flatHeight', 'scopeWidth', 'scopeHeight'),
  );

  /** Scope calculations values. */
  public readonly values$: Observable<ImageValues> = this.formValues$.pipe(
    map(({ screenConfiguration, flatWidth, flatHeight, scopeWidth, scopeHeight }) => {
      // TODO (Danil K): We can remove this sizes and get it from form service directly after refactorig.
      const flatImageSize = {
        width: flatWidth,
        height: flatHeight,
      };
      const scopeImageSize = {
        width: scopeWidth,
        height: scopeHeight,
      };

      let flatChipWidthRatio = 0;

      if (ScreenConfiguration.isFixedWidth(screenConfiguration)) {
        flatChipWidthRatio = CONSTANTS.CHIP_FIXED_WIDTH_ASPECT_RATIO;
      } else if (ScreenConfiguration.isFixedHeight(screenConfiguration) ||
        ScreenConfiguration.isNonStandardAspect(screenConfiguration)) {
        flatChipWidthRatio = CONSTANTS.CHIP_ASPECT_RATIO;
      }

      const flatCenterLumenAreaValue = flatWidth * flatWidth / CONSTANTS.CHIP_ASPECT_RATIO;
      const flatCenterLumenArea = Number(flatCenterLumenAreaValue.toFixed(2));
      const scopeCenterLumenAreaValue = scopeWidth * scopeWidth / CONSTANTS.CHIP_ASPECT_RATIO;
      const scopeCenterLumenArea = Number(scopeCenterLumenAreaValue.toFixed(2));

      return { flatImageSize, scopeImageSize, flatChipWidthRatio, flatCenterLumenArea, scopeCenterLumenArea };
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
