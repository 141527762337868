import { ChangeDetectorRef, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/**
 * Base Control Value Accessor
 * You can inherits from this class and:
 *  use `value` prop to get value
 *  use `setValue(value)` function to set new value
 */
@Directive()
export abstract class ControlValueAccessorBase<T> implements ControlValueAccessor {

  /** State of disabling */
  public disabled = false;

  /** Current value (in this component) */
  protected internalValue!: T;

  /** @constructor */
  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  /** Get readonly internal value */
  public get value(): T {
    return this.internalValue;
  }

  /** When user change value in UI, notify parent */
  public setValue(val: T): void {
    this.internalValue = val;
    this.onChange(val);
    this.onTouched();
  }

  /** Parent's handler for onChange event */
  public onChange(_val: any): void {
  }

  /** Parent's handler for onTouch event */
  public onTouched(): void {
  }

  /** Register listener for onChange (called from parent) */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** Register listener for OnTouched (called from parent) */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Set disable state */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  /** Set value in component (called from parent) */
  public writeValue(obj: T): void {
    this.internalValue = obj;
    this.afterWriteValue();
    this.changeDetectorRef.markForCheck();
  }

  /** HOOK which call after new value set */
  public afterWriteValue(): void {
  }
}
