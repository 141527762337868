import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { AppCardComponent } from './components/app-card/app-card.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { CalculationsTableComponent } from './components/calculations-table/calculations-table.component';
import { CalculationsTableRowComponent } from './components/calculations-table-row/calculations-table-row.component';
import { EquipmentSelectionCardComponent } from './components/equipment-selection-card/equipment-selection-card.component';
import { EquipmentTableComponent } from './components/equipment-table/equipment-table.component';
import { FakeEquipmentItemComponent } from './components/fake-equipment-item/fake-equipment-item.component';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { HazardDistanceIllustrationComponent } from './components/hazard-distance-illustration/hazard-distance-illustration.component';
import { HazardDistanceTableComponent } from './components/hazard-distance-table/hazard-distance-table.component';
import { HazardDistanceTextComponent } from './components/hazard-distance-text/hazard-distance-text.component';
import { SizeArrowsWrapperComponent } from './components/size-arrows-wrapper/size-arrows-wrapper.component';
import { TableNoteComponent } from './components/table-note/table-note.component';
import { TooltipButtonComponent } from './components/tooltip-button/tooltip-button.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { NgLetDirective } from './directives/ng-let';
import { PhoneInputDirective } from './directives/phone-input.directive';
import { MaterialModule } from './material.module';
import { RoundNumberPipe } from './pipes';

const EXPORTED_DECLARATIONS = [
  AppValidationDirective,
  FormControlValidationMessageComponent,
  AppHeaderComponent,
  AppCardComponent,
  TooltipButtonComponent,
  FakeEquipmentItemComponent,
  CalculationsTableComponent,
  CalculationsTableRowComponent,
  RoundNumberPipe,
  HazardDistanceIllustrationComponent,
  HazardDistanceTableComponent,
  HazardDistanceTextComponent,
  NgLetDirective,
  TableNoteComponent,
  SizeArrowsWrapperComponent,
  EquipmentSelectionCardComponent,
  EquipmentTableComponent,
  PhoneInputDirective,
];

const EXPORTED_MODULES = [
  MaterialModule,
  TranslateModule,
  MatDialogModule,
];

/**
 * Common shared module.
 * Contains reusable components, directives and etc can be shared through all apps in the project.
 */
@NgModule({
  declarations: [
    ValidationMessageComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    ...EXPORTED_MODULES,
    ReactiveFormsModule,
  ],
  providers: [
    DecimalPipe,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
    ...EXPORTED_MODULES,
  ],
})
export class CommonSharedModule {
}
