import { EquipmentBase } from './equipment-base';

/**
 * Lamp
 */
export class Lamp extends EquipmentBase {
  /** Max lumens */
  public maxLumens: number;

  /** Warranty hours */
  public warrantyHours: number;
  /**
   * Flag indicating that the Lamp can only be selected manually.
   * If true, then this element should not participate in automatic selection
   * (for example, after applying some filter). The user can select the element only manually.
   */
  public manualSelectionOnly: boolean;

  public constructor(data: ConstructorInitArg<Lamp>) {
    super(data);
    this.maxLumens = data.maxLumens;
    this.warrantyHours = data.warrantyHours;
    this.manualSelectionOnly = data.manualSelectionOnly;
  }
}
