import { marker } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * Lens options.
 */
export enum LensOptions {
  /**
   * High Brightness.
   */
  HighBrightness = 'HB',
  /**
   * High Contrast.
   */
  HighContrast = 'HC',
  /**
   * Ultra High Contrast.
   */
  UltraHighContrast = 'UHC',
  /**
   * High Brightness Premium.
   */
  HighBrightnessPremium = 'HB-P',
  /**
   * High Contrast Premium.
   */
  HighContrastPremium = 'HC-P',
  /**
   * Ultra High Contrast Premium.
   */
  UltraHighContrastPremium = 'UHC-P',
}

/**
 * Helper methods for Enum.
 */
export namespace LensOptions {
  const TO_TITLE_MAP: Record<LensOptions, string> = {
    [LensOptions.HighBrightness]: marker('lens-options.high-brightness'),
    [LensOptions.HighContrast]: marker('lens-options.high-contrast'),
    [LensOptions.UltraHighContrast]: marker('lens-options.ultra-high-brightness'),
    [LensOptions.HighBrightnessPremium]: marker('lens-options.high-brightness-premium'),
    [LensOptions.HighContrastPremium]: marker('lens-options.high-contrast-premium'),
    [LensOptions.UltraHighContrastPremium]: marker('lens-options.ultra-high-brightness-premium'),
  };

  /**
   * Convert a certain Enum value to readable title.
   * @param value value of Enum
   */
  export function toReadable(value: LensOptions): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Get list of Enum items.
   */
  export function toArray(): LensOptions[] {
    return [
      LensOptions.HighBrightness,
      LensOptions.HighContrast,
      LensOptions.UltraHighContrast,
      LensOptions.HighBrightnessPremium,
      LensOptions.HighContrastPremium,
      LensOptions.UltraHighContrastPremium,
    ];
  }

  /**
   * Track by function.
   */
  export function trackBy(_index: number, item: LensOptions): LensOptions {
    return item;
  }

  /**
   * Comparator for sort function.
   */
  export function sortComparator(first: LensOptions, second: LensOptions): number {
    const allItems = LensOptions.toArray();
    return allItems.indexOf(first) - allItems.indexOf(second);
  }

  /**
   * Check lens type.
   * @param value Value.
   */
  export function isHighBrightnessOrPremium(value: LensOptions): boolean {
    return [LensOptions.HighBrightness, LensOptions.HighBrightnessPremium].includes(value);
  }

  /**
   * Check lens type.
   * @param value Value.
   */
  export function isHighContrastOrPremium(value: LensOptions): boolean {
    return [LensOptions.HighContrast, LensOptions.HighContrastPremium].includes(value);
  }

  /**
   * Check lens type.
   * @param value Value.
   */
  export function isUltraHighContrastOrPremium(value: LensOptions): boolean {
    return [LensOptions.UltraHighContrast, LensOptions.UltraHighContrastPremium].includes(value);
  }
}
