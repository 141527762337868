import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import {
  ValidationErrorCode,
  MatchErrorData,
  LengthErrorData,
  AppErrorData,
  MinValueErrorData,
  MaxValueErrorData,
} from '../../../core/models/validation-error-code';

/**
 * Validation error messages.
 */
const validationErrorMessageFactories = {
  [ValidationErrorCode.Email]:
    (tr: TranslateService) => tr.get(marker('validation.email')),
  [ValidationErrorCode.Required]:
    (tr: TranslateService) => tr.get(marker('validation.required')),
  [ValidationErrorCode.Match]:
    (tr: TranslateService, { controlTitle }: MatchErrorData) => tr.get(marker('validation.match'), { v: controlTitle }),
  [ValidationErrorCode.MinLength]:
    (tr: TranslateService, { requiredLength }: LengthErrorData) => tr.get(marker('validation.minLength'), { v: requiredLength }),
  [ValidationErrorCode.MaxLength]:
    (tr: TranslateService, { requiredLength }: LengthErrorData) => tr.get(marker('validation.maxLength'), { v: requiredLength }),
  [ValidationErrorCode.Pattern]:
    (tr: TranslateService) => tr.get(marker('validation.pattern')),
  [ValidationErrorCode.AppError]:
    (tr: TranslateService, { message }: AppErrorData) => tr.get(marker(message)),
  [ValidationErrorCode.Min]:
    (tr: TranslateService, { min }: MinValueErrorData) => tr.get(marker('validation.minValue'), { v: min }),
  [ValidationErrorCode.Max]:
    (tr: TranslateService, { max }: MaxValueErrorData) => tr.get(marker('validation.maxValue'), { v: max }),
  [ValidationErrorCode.Greater]:
    (tr: TranslateService, { controlTitle }: any) => tr.get(marker('validation.greater'), { v: controlTitle }),
};

/**
 * Validation error renderer component.
 * Renders first error from control errors.
 */
@Component({
  selector: 'cpcac-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent implements OnChanges {
  public constructor(private readonly translateService: TranslateService) { }

  /**
   * Validation errors.
   */
  @Input()
  public errors: ValidationErrors | null = null;

  private updateErrorMessage$ = new Subject<void>();

  /**
   * Error message as a string.
   */
  public errorMessage$ = this.updateErrorMessage$.pipe(
    startWith(undefined),
    switchMap(() => {
      if (this.errors == null) {
        return of(null);
      }
      const errorCode = Object.keys(this.errors)[0] as ValidationErrorCode;
      return this.getErrorMessage(errorCode, this.errors[errorCode]);
    }),
  );

  /**
   * Get error message for specific validation error.
   * @param errorCode Error code (minlength, required and etc.)
   * @param errorData Data of error. See details of HTTP validation errors or implementation of custom.
   * For instance data of minlength error is: { actualLength, requiredLength }.
   */
  private getErrorMessage(errorCode: ValidationErrorCode, errorData: any): Observable<string> {
    const factory = validationErrorMessageFactories[errorCode];
    if (factory == null) {
      console.warn(`Can not find validation message factory for error: ${errorCode}`);
      return of('Value is not valid');
    }
    return factory(this.translateService, errorData);
  }

  /** @inheritDoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors) {
      this.updateErrorMessage$.next();
    }
  }
}
