<mat-toolbar class="mat-elevation-z6" color="primary">
  <div class="logo-wrapper" (click)="toggleSidebar()">
    <img src="assets/Christie-logo.svg" [alt]="'header.logo-alt'|translate">
  </div>
  <span class="title hide-on-mobile" translate>
    header.title
  </span>
  <div class="spacer"></div>

  <a
    mat-button
    class="help-guide-button"
    target="_blank"
    rel="noopener noreferrer"
    title="Help Guide"
    aria-label="Help Guide"
    rel="noreferrer"
    [href]="helpGuideLink">
    <mat-icon>help</mat-icon>
    <span class="hide-on-mobile" translate>header.help-guide</span>
  </a>

  <button
    mat-button
    aria-label="Expand more"
    [matMenuTriggerFor]="menu">
    <mat-icon>public</mat-icon>
    {{ Language.toReadable(selectedLang) | translate }}
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      *ngFor="let lang of Language.toArray(); trackBy: Language.trackBy"
      mat-menu-item
      aria-label="Language selection"
      (click)="selectLang(lang)">
      {{ Language.toReadable(lang) | translate }}
    </button>
  </mat-menu>

  <button
    mat-icon-button
    class="hide-on-desktop"
    aria-label="Sidebar toggle"
    (click)="toggleSidebar()">
    <mat-icon [class.inactive]="(isMobileSidebarOpened$|async) === false">tune</mat-icon>
  </button>
</mat-toolbar>
